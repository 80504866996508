import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { withErrorHandler } from '@wix/bookings-viewer-error-handler';
import { bookingsWidgetPageLoaded } from '@wix/bi-logger-wixboost-ugc/v2';
import { REQUESTED_STAFF_DEEP_LINK_ORIGIN } from '../../consts';
import { SettingsKeys } from '../../../legacy/types';
import {
  getAppSettingsClient,
  getUserSettings,
  updatePublicData,
} from '../../../legacy/appSettings/getAppSettings';
import {
  mergeAppSettingsToSettingsParams,
  mergeAppSettingsToStyleParams,
  ServiceListSettings,
} from '../../../legacy/appSettings/appSettings';
import {
  ViewModeToWidgetNameMap,
  WidgetNamePhase1,
} from '../../utils/bi/consts';
import {
  createWidgetErrorStateViewModel,
  createWidgetViewModel,
  WidgetViewModel,
} from '../../viewModel/viewModel';
import { createWidgetActions } from '../../actions/actions';
import {
  EnrichedService,
  FilterOption,
  ServicesPagingMetadata,
  ViewMode,
} from '../../types/types';
import { shouldShowDummyContent } from '../../utils/dummyContent/dummyContent';
import { getPresetId, onLocationURLChange } from './controllerPrePageReady';
import { mergeOpacityToColor } from '../../../legacy/utils';
import { mapPublicDataOverridesToPublicData } from '../../utils/anywhere/anywhere';
import {
  navigateToHeadlessIfNeeded,
  NavigationType,
} from '@wix/wix-to-headless-redirect-client';
import {
  mergePresetIdToPublicData,
  mergePresetIdToStyleParams,
} from './updateSettings';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import {
  getScale,
  getUrlQueryParamValue,
  isRunningInIframe,
  BookingsQueryParams,
  isPricingPlanInstalled as isPricingPlanInstalledUtils,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { BookingsAPI } from '../../api/BookingsApi';
import {
  enrichServices,
  getBusinessLocationsFromServices,
} from '../../utils/services/services';
import {
  ServiceListContext,
  createServiceListContext,
} from '../../context/createServiceListContext';
import { Location } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  createDummyFilterOptions,
  createDummyServicesDto,
} from '../../utils/dummyContent/dummyServicesDto/dummyServicesDto';
import { GetBusinessResponse } from '@wix/ambassador-services-catalog-server/types';
import { getPlatformBiLoggerDefaultsConfig } from '../../utils/bi/getPlatformBiLoggerDefaultsConfig';
import {
  getFilterOptions,
  isListFilterVisible,
  isShowAllServicesFilterOptionVisible,
} from '../../utils/filterOptions/getFilterOptions';
import settingsParams from './settingsParams';
import {
  calculateNumberOfPages,
  getPaginationSEOMetadata,
  getRequestedPageFromQueryParam,
  getServicesPerPage,
} from '../../utils/pagination/pagination';
import { bookingsUoUFlowWidgetLoadSrc16Evid32 } from '@wix/bi-logger-bookings-data/v2';
import { withBookingsViewerCache } from '@wix/bookings-viewer-cache';

export const createControllerFactory = (viewMode: ViewMode) => {
  const createController: CreateControllerFn = async ({
    flowAPI,
    dangerousPublicDataOverride,
    dangerousStylesOverride,
  }: ControllerParams) => {
    if (flowAPI.experiments.enabled('specs.bookings.useBookingsViewerCache')) {
      flowAPI = withBookingsViewerCache(flowAPI);
    }
    const {
      controllerConfig,
      environment: { isSSR, isEditor, isEditorX, isADI },
      bi,
      httpClient,
      errorHandler,
    } = flowAPI;

    if (flowAPI.experiments.enabled('specs.bookings.withErrorHandler')) {
      withErrorHandler({ httpClient, errorHandler });
    }
    const { config, wixCodeApi, setProps } = controllerConfig;

    let businessLocations: Location[];
    let bookingsApi: BookingsAPI;
    let enrichedServices: EnrichedService[];
    let serviceListContext: ServiceListContext;
    let widgetViewModel: WidgetViewModel;
    let servicesPagingMetadata: ServicesPagingMetadata;
    let scale: number;
    let currentUserAppSettings: ServiceListSettings,
      currentUserStylesParam: any;
    let publicData: any,
      stylesProp: any,
      shouldWorkWithAppSettings: boolean,
      userSettings: any;

    let presetId = getPresetId(config, isEditorX);

    const biLoggerDefaultConfigurations = getPlatformBiLoggerDefaultsConfig(
      flowAPI,
      WidgetNamePhase1,
    );
    bi?.updateDefaults(biLoggerDefaultConfigurations);

    onLocationURLChange(wixCodeApi, () => pageReady());

    // When moving to app reflow, this should move to the App Reflow router (using getHeadlessUrl instead of navigateToHeadlessIfNeeded)
    const { navigatedToHeadless } = navigateToHeadlessIfNeeded({
      navParams: {
        logicalName: NavigationType.BOOKINGS_SERVICE_LIST,
      },
      location: wixCodeApi.location,
    });
    if (navigatedToHeadless) {
      // stop rendering
      return {
        pageReady: async () => {},
      };
    }

    const pageReady = async () => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { config } = controllerConfig;
      const appSettingsClient = getAppSettingsClient(flowAPI);

      shouldWorkWithAppSettings = !!config.externalId;

      let filterOptions: FilterOption[] = [];

      const referralInfo = getUrlQueryParamValue(
        wixCodeApi,
        BookingsQueryParams.REFERRAL,
      );
      bi?.report(
        bookingsUoUFlowWidgetLoadSrc16Evid32({
          widget_name: ViewModeToWidgetNameMap[viewMode],
          referralInfo,
        }),
      );

      const setErrorState = (error: unknown) => {
        console.error(error);
        const widgetErrorStateViewModel = createWidgetErrorStateViewModel({
          flowAPI,
        });
        setProps({
          widgetErrorStateViewModel,
        });
      };

      if (shouldWorkWithAppSettings) {
        try {
          userSettings = await getUserSettings(appSettingsClient, presetId);
        } catch (error) {
          return setErrorState(error);
        }
      }

      const isPricingPlanInstalled = await isPricingPlanInstalledUtils(
        flowAPI.controllerConfig.wixCodeApi,
      );

      bookingsApi = new BookingsAPI({
        appSettings: userSettings,
        flowAPI,
        shouldWorkWithAppSettings,
      });

      let queryServicesResponse, getBusinessResponse;

      const getBusinessInfoPromise = bookingsApi
        .getBusinessInfo()
        .catch((error: unknown) => {
          console.log(error);
          const defaultBusinessInfo: GetBusinessResponse = {
            activeFeatures: {
              applicableForCourse: true,
              applicableForGroups: true,
              applicableForIndividual: true,
              applicableForPayments: true,
              applicableForReminders: true,
              applicableForSmsReminders: true,
            },
            businessProperties: {},
            info: {
              name: '',
              language: 'en',
              timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
              locale: 'en',
            },
          };

          return defaultBusinessInfo;
        });

      if (
        isListFilterVisible({
          flowAPI,
          appSettings: shouldWorkWithAppSettings && userSettings,
        }) &&
        !isShowAllServicesFilterOptionVisible({
          appSettings: shouldWorkWithAppSettings && userSettings,
          flowAPI,
        })
      ) {
        filterOptions = await getFilterOptions({
          flowAPI,
          bookingsApi,
          appSettings: shouldWorkWithAppSettings && userSettings,
        });

        const selectedOption = filterOptions.find(
          (option) => option.isSelected,
        );

        [queryServicesResponse, getBusinessResponse] = await Promise.all([
          bookingsApi
            .queryServices({
              selectedFilterOptionId: selectedOption?.id,
            })
            .catch((error) => {
              return setErrorState(error);
            }),
          getBusinessInfoPromise,
        ]);
      } else {
        [filterOptions, queryServicesResponse, getBusinessResponse] =
          await Promise.all([
            getFilterOptions({
              flowAPI,
              bookingsApi,
              appSettings: shouldWorkWithAppSettings && userSettings,
            }),
            bookingsApi.queryServices().catch((error) => {
              return setErrorState(error);
            }),
            getBusinessInfoPromise,
          ]);
      }

      if (!queryServicesResponse) {
        return;
      }

      const { pagingMetadata, services: initialServices } =
        queryServicesResponse;
      const { total, offset } = pagingMetadata || {};

      const servicesPerPage = getServicesPerPage(flowAPI);
      const requestedPage = offset ? offset / servicesPerPage + 1 : 1;

      const services = initialServices ?? [];
      const activeFeatures = getBusinessResponse.activeFeatures!;

      servicesPagingMetadata = {
        totalPages: calculateNumberOfPages(servicesPerPage, total),
        minPageLoaded: requestedPage,
        maxPageLoaded: requestedPage,
      };

      serviceListContext = await createServiceListContext({
        getBusinessResponse,
        flowAPI,
        isPricingPlanInstalled,
        viewMode,
      });

      const { isAnywhereFlow: isAnywhereFlowInd, businessInfo } =
        serviceListContext;

      enrichedServices = await enrichServices({
        flowAPI,
        activeFeatures,
        isPricingPlanInstalled,
        services,
        isAnywhereFlow: serviceListContext.isAnywhereFlow,
      });
      businessLocations = getBusinessLocationsFromServices(enrichedServices);

      scale = await getScale();

      if (shouldWorkWithAppSettings) {
        const userStylesColorsWithOpacity = {};
        (
          Object.keys(config.style.styleParams.colors || {}) as SettingsKeys[]
        ).forEach((colorKey) => {
          // @ts-expect-error
          userStylesColorsWithOpacity[colorKey] = {
            ...config.style.styleParams!.colors![colorKey],
            value: userSettings[colorKey]
              ? mergeOpacityToColor(
                  userSettings[colorKey].value,
                  config.style.styleParams!.colors![colorKey]
                    .value as any as string,
                )
              : config.style.styleParams!.colors![colorKey].value,
          };
        });

        userSettings = {
          ...userSettings,
          ...(isAnywhereFlowInd ? {} : config.style.styleParams.fonts),
          ...userStylesColorsWithOpacity,
        };
        currentUserAppSettings = userSettings;
        currentUserStylesParam = config.style.styleParams;

        stylesProp = dangerousStylesOverride(
          mergeAppSettingsToStyleParams(
            userSettings,
            {
              booleans: {},
              numbers: {},
              googleFontsCssUrl: '',
            },
            presetId,
          ) as any,
        );

        const publicDataOverrides = isAnywhereFlowInd
          ? mapPublicDataOverridesToPublicData(businessInfo?.name)
          : undefined;

        publicData = dangerousPublicDataOverride(
          mergeAppSettingsToSettingsParams(
            userSettings,
            config.publicData,
            presetId,
            publicDataOverrides,
          ),
        );
      } else {
        stylesProp = dangerousStylesOverride(
          mergePresetIdToStyleParams(config.style.styleParams, presetId),
        );

        publicData = dangerousPublicDataOverride(
          mergePresetIdToPublicData(config.publicData, presetId),
        );
      }

      if (
        shouldShowDummyContent({
          services: enrichedServices,
          flowAPI,
        })
      ) {
        enrichedServices = createDummyServicesDto(flowAPI, presetId);
        filterOptions = createDummyFilterOptions(
          presetId,
          flowAPI,
          businessLocations,
          enrichedServices,
        );
      }

      widgetViewModel = await createWidgetViewModel({
        scale,
        flowAPI,
        viewMode,
        servicesPagingMetadata,
        shouldWorkWithAppSettings,
        businessLocations,
        allServices: enrichedServices,
        serviceListContext,
        appSettings: userSettings,
        filterOptions,
      });

      const widgetActions = createWidgetActions({
        activeFeatures,
        isPricingPlanInstalled,
        widgetViewModel,
        bookingsApi,
        flowAPI,
        setProps,
        services: enrichedServices,
        serviceListContext,
      });

      if (!isSSR) {
        widgetActions.getAdditionalServicesData();
      }

      if (!isSSR && !isEditor) {
        const origin =
          getUrlQueryParamValue(wixCodeApi, BookingsQueryParams.STAFF) ||
          getUrlQueryParamValue(wixCodeApi, BookingsQueryParams.RESOURCE)
            ? REQUESTED_STAFF_DEEP_LINK_ORIGIN
            : undefined;

        const filterServicesBy =
          (shouldWorkWithAppSettings && userSettings?.CATEGORIES_TYPE) ||
          flowAPI.settings.get(settingsParams.filterServicesBy);

        bi?.report(
          bookingsWidgetPageLoaded({
            numOfTabs: widgetViewModel.headerViewModel.isListFilterVisible
              ? widgetViewModel.filterOptions.length
              : 0,
            numOfServices: widgetViewModel.services.length,
            isExplorePlans:
              widgetViewModel.bodyViewModel
                .atLeastOneServiceHasExplorePlansLink,
            origin,
            layout: widgetViewModel.serviceListLayout,
            areTabsLocationsOrServiceCategories: filterServicesBy,
          }),
        );
      }

      if (shouldWorkWithAppSettings && isEditor && isRunningInIframe()) {
        appSettingsClient.onChange(
          async (newUserSettings: ServiceListSettings) => {
            currentUserAppSettings = newUserSettings;
            updatePublicData({
              newUserSettings,
              presetId,
              flowAPI,
              dangerousStylesOverride,
              dangerousPublicDataOverride,
              scale,
              viewMode,
              newUserStylesSettings: currentUserStylesParam,
              shouldWorkWithAppSettings,
              businessLocations,
              serviceListContext,
              services: enrichedServices,
              bookingsApi,
              servicesPagingMetadata,
            });
          },
        );
      }
      if (
        getRequestedPageFromQueryParam(wixCodeApi) >
        servicesPagingMetadata.totalPages
      ) {
        wixCodeApi.seo.setSeoStatusCode(404);
      }

      setProps({
        ...stylesProp,
        ...publicData,
        widgetViewModel: { ...widgetViewModel },
        widgetActions,
        fitToContentHeight: true,
      });

      wixCodeApi.seo.renderSEOTags({
        itemType: ITEM_TYPES.SERVICES_COMPONENT,
        itemData: {
          services: enrichedServices,
          pagination: getPaginationSEOMetadata(
            wixCodeApi,
            servicesPagingMetadata,
          ),
        },
      });
    };
    return {
      pageReady,
      updateConfig(_$w, data) {
        shouldWorkWithAppSettings = !!data.externalId;
        if (shouldWorkWithAppSettings) {
          // Should have been relevant for ADI Editor OOI only when changing the design,
          // for other editors it's not relevant since all data is on app settings, and we have updateAppSettings function.
          // When we tried to wrap it isADI & !isRunningInIframe the component was rendered with defaults settings when we opened the settings panel.
          // WA - save the current most updated app settings data and call generic function to calculate public data/ styles param and widget view model
          presetId = isADI ? data.publicData.COMPONENT.presetId : presetId;
          currentUserStylesParam = data.style.styleParams;
          updatePublicData({
            businessLocations,
            dangerousPublicDataOverride,
            dangerousStylesOverride,
            flowAPI,
            newUserSettings: currentUserAppSettings,
            presetId,
            scale,
            serviceListContext,
            services: enrichedServices,
            shouldWorkWithAppSettings,
            viewMode,
            newUserStylesSettings: currentUserStylesParam,
            bookingsApi,
            servicesPagingMetadata,
          });
        } else {
          pageReady();
        }
      },
      updateAppSettings: (_event: any, updates: { [key: string]: any }) => {
        if (shouldWorkWithAppSettings) {
          // Relevant for editor OOI, changes on app settings, include texts/colors/fonts changes - covers all
          const { payload }: { payload: ServiceListSettings } = updates as any;
          if (!isRunningInIframe()) {
            currentUserAppSettings = payload;
            updatePublicData({
              businessLocations,
              dangerousPublicDataOverride,
              dangerousStylesOverride,
              flowAPI,
              newUserSettings: currentUserAppSettings,
              presetId,
              scale,
              serviceListContext,
              services: enrichedServices,
              shouldWorkWithAppSettings,
              viewMode,
              newUserStylesSettings: currentUserStylesParam,
              bookingsApi,
              servicesPagingMetadata,
            });
          }
        }
      },
    };
  };

  return createController;
};

export default createControllerFactory(ViewMode.PAGE);
